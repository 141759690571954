import { AuthConfig } from "angular-oauth2-oidc";
const oidc_config: AuthConfig = {
  // Url of the Identity Provider
  // issuer: "https://auth.dev.myviewboard.com/oidc/v1",
  issuer: "https://auth.dev.myviewboard.com/oidc/v1",

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + "/home",

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: "mvb-core-service",

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  responseType: "code",
  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: "openid profile email",

  showDebugInformation: true,
  timeoutFactor: 0.8,
};
export const environment = {
  production: false,
  apiUrl: "https://devapi.myviewboard.com",
  apiv2Url: "https://api.dev.myviewboard.com",
  sensApiUrl: "https://sens.dev.myviewboard.com",
  mqttUrl: "https://stage-control.myviewboard.com",
  authUrl: "https://auth.dev.myviewboard.com",
  extensionId: "lodmdalapilnodpohlpcfedmbjpamkli",
  appInsights: { instrumentationKey: "f04ac989-013d-48d6-997e-386b04280e72" },
  pathTo_HostBoard: "/classroom/teacher",
  pathTo_JoinBoard: "/classroom/student",
  dcUrl: "https://insight.stage.myviewboard.cloud",
  dmUrl: "https://dev.myviewboard.com/mydashboard/dm",
  imUrl: "https://dev.myviewboard.com/mydashboard/im",
  clipsUrl: "https://stage.myviewboardclips.com",
  originalsUrl: "https://dev.myviewboard.com/originals",
  isLiveChat: true,
  isGA: true,
  subUrl: "https://subscription.dev.myviewboard.com",
  mspUrl: "https://msp.dev.myviewboard.com",
  oidc_config,
  domainURL: ".dev.myviewboard.com",
  communityUrl: 'https://community.stage.myviewboard.com'
};
